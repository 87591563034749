import React from "react"
import { graphql } from "gatsby" // highlight-line
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Box } from "theme-ui"

import ViewCounter from "../components/ViewCounter"
import WordCategory from "../components/WordCategory"
import Flag from "../components/Flag"

export const query = graphql`
  query($id: Int!) {
    allMysqlLanguage(filter: { mysqlId: { eq: $id } }) {
      edges {
        node {
          language
          country
          iso
        }
      }
    }
    allMysqlVocabulary(
      filter: { language_id: { eq: $id } }
      sort: { fields: [category_name], order: ASC }
    ) {
      group(field: category_name) {
        fieldValue
        edges {
          node {
            vid
            word_name
            word_translated
            category_name
            category_id
            base_words_id
            language_id
          }
        }
      }
    }
  }
`

// highlight-start
const CountryPost = ({ data, pageContext, location }) => {
  const { country } = data.allMysqlLanguage.edges[0].node
  const allWords = data.allMysqlVocabulary.group

  // console.log(allWords.edges.node);

  const { language, countryISO, languageCode } = pageContext


  const title = languageCode === "de-DE" ? `German football words` : `Fußballwortschatz auf ${language}`

  let props = {
    location,
  }

  let pageDescription = languageCode === "de-DE" ? `These are the most important german football terms you should know for the next big tournament. You can also listen to the translation!` : `Du möchtest wissen wie Freistoß, Elfmeter, etc. auf ${language} heißt? Hier findest du die wichtigsten Fußballbegriffe auf ${language} - zum Lesen und Anhören`;

  // highlight-end
  return (
    <Layout {...props} title={title} subPage>
      <SEO title={title} description={pageDescription} />

      <Box
        sx={{
          textAlign: `right`,
        }}
      >
        <Flag
          title={country}
          aria-label={country}
          alt={`${country} Flagge`}
          countryCode={countryISO}
          className="flag-dark"
          svg
        />
      </Box>

      {language ? <ViewCounter id={language} increase={true} /> : null}

      <Box sx={{ fontSize: [1], marginTop: [4] }}>
        {allWords.map((words, index) => {
          return (
            <WordCategory
              key={index}
              words={words.edges}
              categoryId={words.edges[0].node.category_id}
              languageCode={languageCode}
            />
          )
        })}
      </Box>
    </Layout>
  )
}

export default CountryPost
