/** @jsx jsx */

// import
import { jsx, Box } from 'theme-ui';
import useSound from 'use-sound';
import { AiOutlinePause, AiOutlinePlayCircle } from "react-icons/ai"

// const Play = ({ onPlayerClick }) => {
//   return (
//     <svg className="button" viewBox="0 0 60 60" onClick={onPlayerClick}>
//       <polygon points="0,0 50,30 0,60" />
//     </svg>
//   )
// }

// const Pause = ({ onPlayerClick }) => {
//   return (
//     <svg className="button" viewBox="0 0 60 60" onClick={onPlayerClick}>
//       <polygon points="0,0 15,0 15,60 0,60" />
//       <polygon points="25,0 40,0 40,60 25,60" />
//     </svg>
//   )
// }

const AudioPlayer = props => {

  const { fileName } = props
  const audioUrl = `${__PATH_PREFIX__}/audio/${fileName}.mp3`;
  const [play, { stop, isPlaying }] = useSound(audioUrl);


  return (
    <Box>
      {isPlaying ? <AiOutlinePause sx={{ cursor: 'pointer', fontSize: 4, color: 'accent' }} onClick={stop} /> : <AiOutlinePlayCircle sx={{ cursor: 'pointer', fontSize: 4, color: 'textMuted' }} onClick={play} />}
    </Box>
  )

}

export default AudioPlayer

// use-sound
