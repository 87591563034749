/** @jsx jsx */

import { transparentize } from "@theme-ui/color"
import { Box, Card, Flex, Grid, Heading, jsx, Text } from "theme-ui"

import {
  GiSoccerBall,
  GiSoccerKick,
  GiSoccerField,
  GiTrophyCup,
  GiHamburger
} from "react-icons/gi"

import AudioPlayer from "./AudioPlayer"

const WordCategory = props => {
  const { words, categoryId, languageCode } = props

  // 1	Aufstellung / Akteure	aufstellung_akteure
  // 2	Spielfeld	spielfeld
  // 3	Spiel	spiel
  // 4	Turnier	turnier
  // 5	Essen und Trinken	essen_trinken


  // TO DO: somehow clean this up

  const categoriesDE = {
    1: "Aufstellung / Akteure",
    2: "Spielfeld",
    3: "Spiel",
    4: "Turnier",
    5: "Essen und Trinken"
  }

  const categoriesEN = {
    1: "Lineup / protagonists",
    2: "Pitch",
    3: "Game",
    4: "Tournament",
    5: "Food and drink"
  }

  let CategoryIcon

  switch (categoryId) {
    case 1:
      CategoryIcon = GiSoccerKick
      break
    case 2:
      CategoryIcon = GiSoccerField
      break
    case 3:
      CategoryIcon = GiSoccerBall
      break
    case 4:
      CategoryIcon = GiTrophyCup
      break
    case 5:
      CategoryIcon = GiHamburger
      break
    default:
      CategoryIcon = GiSoccerKick
  }

  // console.log(words)

  return (
    <Grid
      sx={{
        marginBottom: 4,
      }}
      gap={4}
      columns={["auto", "33% 1fr"]}
    >
      <Flex
        sx={{
          flexDirection: `column`,
          alignItems: [`center`, `flex-end`],
          p: 2,
        }}
      >
        <CategoryIcon
          sx={{
            fontSize: 8,
            color: t => t.colors.orange[4],
            mb: 1,
          }}
        />

        <Heading
          as="h2"
          sx={{
            color: t => t.colors.orange[4],
            fontSize: 4,
            textAlign: [`center`, `right`],
            zIndex: 1,
          }}
        >
          {languageCode === "de-DE" ? categoriesEN[categoryId] : categoriesDE[categoryId]}
        </Heading>
      </Flex>
      <Box
        sx={{
          boxShadow: t => `3px 3px 0px ${t.colors.shadow}`,
        }}
      >
        {words.map((word, index) => {
          return (
            <Card
              sx={{
                display: `flex`,
                variant: `cards.word`,
                justifyContent: `space-between`,
                alignItems: `center`,
                flexDirection: `row`,
                bg: transparentize(`cardBackground`, 0.5),
                "&:hover": {
                  bg: transparentize(`cardBackground`, 0),
                  "& > h3": {
                    color: t => t.colors.accent2,
                  },
                },
                "&:last-child": {
                  border: `none`,
                },
              }}
              key={index}
            >
              <Flex sx={{ flexDirection: 'column' }}>

                <Box>
                  <Text
                    sx={{
                      color: t => t.colors.gray[6],
                    }}
                  >
                    {languageCode !== "de-DE" ? word.node.word_name : word.node.word_translated}
                  </Text>
                </Box>
                <Box
                  sx={{
                    fontFamily: `'Kalam', cursive`,
                    fontSize: 4,
                  }}
                >
                  {languageCode !== "de-DE" ? word.node.word_translated : word.node.word_name}

                </Box>
              </Flex>
              {languageCode &&
                <Box>
                  <AudioPlayer fileName={`${word.node.language_id}_${word.node.base_words_id}`} />
                </Box>
              }
            </Card>
          )
        })}
      </Box>
    </Grid>
  )
}

export default WordCategory
